import React, { useContext, useEffect, useState } from "react";
import EditBillingAddress from "../../pages/Wallet/EditBillingAddress";
import axios from "axios";
import { Context } from "../../context";
import { API_URL } from "../../environment";
import Button from "../Button";
import Modal from "../Modal";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";

const CheckCardDefaultBillingAddress = (props) => {
  const [isShowingModal, toggleModal] = useState(false);
  const [isShowingEmptyCardModal, toggleEmptyCardModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const { state, dispatch } = useContext(Context);
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    toggleModal(false);
    toggleEmptyCardModal(false);
    if (state.userDetails && state.userDetails.user_id) {
      const { userDetails } = state;
      axios
        .get(`${API_URL}/payment/card_info/${userDetails.user_id}`, {
          headers: {
            Authorization: userDetails.token,
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            toggleEmptyCardModal(true);
          }
          res.data.map((item) => {
            if (
              (item.is_default || res.data.length === 1) &&
              !item.billing_info
            ) {
              setSelectedCard(item);
              toggleModal(true);
            }
          });
        });
    }
  }, [state.addCardToken, state.userDetails.user_id, state.userDetails.token]);

  const editBillingAddress = (
    nameOnCard,
    streetAddress,
    locality,
    region,
    postalCode
  ) => {
    const { userDetails } = state;
    dispatch({
      type: "LOADING",
      payload: true,
    });
    //`${API_URL}/payment/billing-info/${selectedCard.payment_cust_id}`,
    axios
      .put(
        `${API_URL}/payment/billing-info/${selectedCard.payment_cust_id}`,
        {
          billing_name: nameOnCard,
          billing_address: streetAddress,
          billing_city: locality,
          billing_state: region,
          billing_postcode: postalCode,
        },
        {
          headers: {
            Authorization: userDetails.token,
          },
        }
      )
      .then(
        (res) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          if (res.data.updated) {
            setSelectedCard(null);
            toggleModal(false);
          }
        },
        () => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      );
  };

  return (
    <>
      {isShowingModal && selectedCard && (
        <EditBillingAddress
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          editBillingAddress={editBillingAddress}
        />
      )}
      {isShowingEmptyCardModal && state.userDetails.palm_id !== "" && (
        <Modal
          isShowing={isShowingEmptyCardModal}
          hide={toggleEmptyCardModal}
          className={"toggle-payment-modal"}
          allowClose={true}
        >
          <div className="d-block pb-3">
            <p>
              <strong>{`You have not yet added a payment method.`}</strong>
            </p>
            <div className="pt-3 d-block">
              <Button
                event={() =>
                  history.push({
                    pathname: "/wallet",
                    state: { prevPath: location.pathname },
                  })
                }
                className="btn btn-main btn-grey btn-small"
              >
                Add Payment Method
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CheckCardDefaultBillingAddress;
