/** @format */

import React, { useContext, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { Context } from "../../context";

const Auth = (props) => {
  let location = useLocation();

  // Get the context provider
  const { state, dispatch } = useContext(Context);

  // useEffect(() => {
  // 	console.log("state.userDetails.token: ", state.userDetails.token);
  // 	if (state.userDetails.token !== "") {
  // 		dispatch({
  // 			type: "USER_AUTH",
  // 			payload: true,
  // 		});
  // 	} else {
  // 		dispatch({
  // 			type: "USER_AUTH",
  // 			payload: false,
  // 		});
  // 	}
  // }, [state.userDetails]);

  return state.auth !== null && !state.auth ? (
    <Redirect to={{ pathname: "/login", state: { from: location } }} />
  ) : (
    props.children
  );
};

export default Auth;
