/** @format */

import "./styles.css";

const Link = ({ href, event, children, ...props }) => {
  return (
    <a href={href} onClick={event} className={props.className} {...props}>
      {children}
    </a>
  );
};

export default Link;
