/** @format */

import React, {useContext, useEffect, useState} from "react";
import { Context } from "../../context";
import useModal from "../../custom-hooks/useModal";
import PageModal from "../../components/PageModal";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { reformatPhone } from "../../utils/validations";
import UpdateName from "../../components/UpdateName";
import UpdateEmail from "../../components/UpdateEmail";
import UpdatePhone from "../../components/UpdatePhone";
import UpdatePassword from "../../components/UpdatePassword";
import DeleteAccount from "../../components/DeleteAccount";
import CheckCardDefaultBillingAddress from "../../components/Card/CheckCardDefaultBillingAddress";
import "./styles.css";

const Profile = () => {
  /* Destructure the state and dispatch values from the Context object
       passed as "value" to the Context.Provider in store.js
    */
  const { state } = useContext(Context);

  const [isShowing, toggle, modalName] = useModal();

  const [editProfile, setEditProfile] = useState({
    modalName: "",
    modalTitle: "",
  });

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {profile_page: profilePage} = state.languages;

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);


  const toggleName = () => {
    setEditProfile({
      modalName: "name",
      modalTitle: profilePage.edit_your_name[currentLang],
    });
    toggle("edit");
  };

  const togglePhone = () => {
    setEditProfile({
      modalName: "phone",
      modalTitle: profilePage.edit_your_phone[currentLang],
    });
    toggle("edit");
  };

  const toggleEmail = () => {
    setEditProfile({
      modalName: "email",
      modalTitle: profilePage.edit_your_email[currentLang],
    });
    toggle("edit");
  };

  const togglePassword = () => {
    setEditProfile({
      modalName: "password",
      modalTitle: profilePage.edit_your_password[currentLang],
    });
    toggle("edit");
  };

  return (
    <div className="container profile">
      <div className="d-block text-center pt-4 register-heading">
        <h1>{profilePage.your_profile[currentLang]}</h1>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-block border-bottom-grey py-4">
            <div className="row">
              <div className="col-9">
                <h2 className="profile-title">{profilePage.name[currentLang]}</h2>
                <p className="profile-data m-0">
                  {`${state.userDetails.first_name} ${state.userDetails.last_name}`}
                </p>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <Button
                  event={toggleName}
                  className="btn btn-outline-secondary btn-sm"
                >
                  {profilePage.edit[currentLang]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="d-block border-bottom-grey py-4">
            <div className="row">
              <div className="col-9">
                <h2 className="profile-title">{profilePage.email[currentLang]}</h2>
                <p className="profile-data m-0">{state.userDetails.username}</p>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <Button
                  event={toggleEmail}
                  className="btn btn-outline-secondary btn-sm"
                >
                  {profilePage.edit[currentLang]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-block border-bottom-grey py-4">
            <div className="row">
              <div className="col-9">
                <h2 className="profile-title">{profilePage.phone_number[currentLang]}</h2>
                <p className="profile-data m-0">
                  {reformatPhone(state.userDetails.billing_phone)}
                </p>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <Button
                  event={togglePhone}
                  className="btn btn-outline-secondary btn-sm"
                >
                  {profilePage.edit[currentLang]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-block border-bottom-grey py-4">
            <div className="row">
              <div className="col-9">
                <h2 className="profile-title">{profilePage.password[currentLang]}</h2>
                <p>
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "0.3rem",
                      marginRight: "0.1rem",
                    }}
                  />
                </p>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <Button
                  event={togglePassword}
                  className="btn btn-outline-secondary btn-sm"
                >
                  {profilePage.edit[currentLang]}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-block py-4">
            <h2 className="profile-title">{profilePage.delete_account[currentLang]}</h2>
            <Button event={() => toggle("delete")} className="btn btn-sm">
              {profilePage.delete_account[currentLang]}
            </Button>
          </div>
        </div>
      </div>
      <PageModal
        modalName={modalName}
        name={"edit"}
        className={"profile-modal"}
        isShowing={isShowing}
        hide={toggle}
        allowClose={true}
        title={editProfile.modalTitle}
      >
        <div className="pb-3">
          {editProfile.modalName === "name" && (
            <UpdateName toggle={() => toggle("edit")} currentLang={currentLang} profilePage={profilePage} />
          )}
          {editProfile.modalName === "email" && (
            <UpdateEmail toggle={() => toggle("edit")} currentLang={currentLang} profilePage={profilePage} />
          )}
          {editProfile.modalName === "phone" && (
            <UpdatePhone toggle={() => toggle("edit")} currentLang={currentLang} profilePage={profilePage} />
          )}
          {editProfile.modalName === "password" && (
            <UpdatePassword toggle={() => toggle("edit")} currentLang={currentLang} profilePage={profilePage} />
          )}
        </div>
      </PageModal>
      <Modal
        modalName={modalName}
        name={"delete"}
        className={"delete-modal"}
        isShowing={isShowing}
        hide={toggle}
        allowClose={true}
        title={profilePage.are_you_sure_delete[currentLang]}
      >
        <div className="py-3 d-block">
          <DeleteAccount toggle={() => toggle("delete")} />
        </div>
        <div className="d-block">
          <Button event={() => toggle("delete")} className="btn btn-sm">
            {profilePage.nevermind[currentLang]}
          </Button>
        </div>
      </Modal>
      <CheckCardDefaultBillingAddress />
    </div>
  );
};

export default Profile;
