import React, { useContext, useEffect } from "react"
import {Context} from "../../context"
import Cookie from "js-cookie"

const LanguageSetting = () => {
    const { dispatch } = useContext(Context);

    const setLang = (lang) => {
        Cookie.remove("storeLang")
        Cookie.set("storeLang", lang)
        dispatch({ type: "LANGUAGE", payload: lang });
    }

    useEffect(() => {
        dispatch({
            type: "LANGUAGE",
            payload: Cookie.get("storeLang") || 'en',
        })
    }, [])

    return (
        <div className="lang-btn-container ml-2 ml-xl3">
            <button
                onClick={() => setLang("en")}
                title="English"
                className="btn p-0 language-btn btn-block btn-lg-inline-block text-white"
            >
                {`English`}
            </button>
            <button
                onClick={() => setLang("fr" )}
                title="Français"
                className="btn p-0 language-btn btn-block btn-lg-inline-block text-white"
            >
                {`Français`}
            </button>
        </div>
    )
}

export default LanguageSetting;
