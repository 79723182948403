/** @format */

import React, { useContext, useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import OtpInput from "react-otp-input";
import Step from "../../components/Step";
import { useHistory } from "react-router-dom";
import PhoneIcon from "@material-ui/icons/Phone";
import Hands from "./img/icon-hands-raised.svg";
import pin from "./img/pin.svg";
import { useUser } from "../../custom-hooks/useUser";
import { Context } from "../../context";
import Button from "../../components/Button";
import Link from "../../components/Link";
import "./styles.css";
import CheckCardDefaultBillingAddress from "../../components/Card/CheckCardDefaultBillingAddress";

const GetStarted = () => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  let history = useHistory();
  const { state, dispatch } = useContext(Context);

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {get_started,your_store,change:changeText,
      get_direction,continue:continueText,
      enter
  } = state.languages;

  useEffect(() => {
      setCurrentLang(state.lang);
  }, [state.lang]);

  const handleChange = (value) => setOtp(value);

  const handleClick = (storedNum, otp, userId, token) => {
    if (!otp || otp.length < 4) {
      setOtpError(get_started.enter_the_code[currentLang]);
      return;
    }
    setOtpError("");
    user.palmEnroll(otp, state.userDetails.token);
  };
  const handleClickComplete = () => history.push("/wallet");

  const user = useUser();

  useEffect(() => {
    user.getStoreAddress("2701914");
  }, []);

  return (
    <div className="container get-started">
      <Pagination step={state.step} get_started={get_started} currentLang={currentLang} />
      <Step
        title={get_started.head_to_your_store[currentLang]}
        description={get_started.once_you_arrived[currentLang]}
        stepNum={1}
        step={state.step}
      >
        <div className="col-12 text-center store-address-section">
          <img src={pin} alt="Hands" className="img-fluid" />
          <div className="d-block mt-3">
            <h3 className="store-name">{your_store[currentLang]}</h3>
            {/* <Link className="col-12">Change</Link> */}
            <a href="#" className="d-block">
                {changeText[currentLang]}
            </a>
          </div>
          <div className="d-block mt-3 font-weight-bold store-address">
            {state.storeAddress.store_address}
          </div>
          <div className="d-block font-weight-bold store-address">
            {state.storeAddress.store_city}, {state.storeAddress.store_state} -{" "}
            {state.storeAddress.store_postcode}
          </div>
          <div className="getDirections">
            <Link
              href={`https://www.google.com/maps/dir//${state.storeAddress.store_address}${state.storeAddress.store_city},${state.storeAddress.store_state} - ${state.storeAddress.store_postcode}`}
              target="_blank"
            >
                {get_direction[currentLang]}
            </Link>
          </div>
          <div className="d-block">
            <span>
              <PhoneIcon style={{ fontSize: "1rem" }} />
            </span>
            {state.storeAddress.user_phone}
          </div>
          {/*<div className="distance">*/}
          {/*	<span>.85</span>*/}
          {/*	{"mi"}*/}
          {/*</div>*/}
        </div>
        <div className="col-12 text-center">
          <Button
            event={() =>
              dispatch({
                type: "STEP",
                payload: 2,
              })
            }
            className="btn btn-main btn-main-lg btn-block mt-4"
          >
              {continueText[currentLang]}
          </Button>
        </div>
      </Step>
      <Step
        title={get_started.enter_the_code[currentLang]}
        description={get_started.go_to_checkout_counter[currentLang]}
        stepNum={2}
        step={state.step}
      >
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span> </span>}
          containerStyle={{ justifyContent: "center" }}
          inputStyle={{
            width: "2rem",
            height: "2rem",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
          }}
          isInputNum
        />
        {otpError && (
          <div className="col-12 text-center otp-error">{otpError}</div>
        )}
        <div className="col-12 text-center">
          <Button
            event={() =>
              handleClick("2705549", otp, 1000001067, state.userDetails.token)
            }
            className="btn btn-main btn-main-lg btn-block mt-4"
          >
              {enter[currentLang]}
          </Button>
        </div>
        {state.apiError && (
          <div className="col-12 text-center otp-error pt-4">
            {state.apiError}
          </div>
        )}
      </Step>
      <Step
        title={get_started.almost_finished[currentLang]}
        description={get_started.follow_on_screen[currentLang]}
        stepNum={3}
        step={state.step}
      ></Step>
      <Step
        img={Hands}
        title={get_started.setup_completed[currentLang]}
        description={get_started.now_you_can_use_your_palm[currentLang]}
        stepNum={4}
        step={state.step}
      >
        <div className="col-12 text-center">
          <div className="d-block text-center pt-4 register-heading">
            <h1 className="noPhone">{get_started.no_phone[currentLang]}.</h1>
            <h1 className="noWallet">{get_started.no_wallet[currentLang]}.</h1>
            <h1 className="noProblem">{get_started.no_problem[currentLang]}.</h1>
          </div>
          <p className="d-block text-center pt-4">
              {get_started.activated_at_store[currentLang]} <b>{state.storeAddress.store_id}</b>
          </p>
          <Button
            event={handleClickComplete}
            className="btn btn-main btn-main-lg btn-block mt-4"
          >
              {get_started.add_credit_cards[currentLang]}
          </Button>
        </div>
      </Step>
      <CheckCardDefaultBillingAddress />
    </div>
  );
};

export default GetStarted;
