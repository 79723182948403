/** @format */

import React from "react";
import Navigation from "../Navigation";
import Logo from "./img/logo.svg";
import "./styles.css";
import LanguageSetting from "../LanguageSetting";

const TopBar = () => {
  return (
    <div className="top-bar container-fluid fixed-top">
      <div className="row h-100 d-flex align-items-center">
        <div className="col-4 ml-auto d-xl-none">
          <Navigation />
        </div>
        <div className="col-4 col-xl-4 mx-auto">
          <img src={Logo} alt="Website logo" className="img-fluid" />
        </div>
        <div className="col-4 col-xl-8 ml-auto d-flex align-items-center justify-content-end">
          <Navigation />
          <LanguageSetting />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
