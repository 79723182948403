/** @format */

import "./styles.css";

const Button = ({ id, event, ...props }) => {
  return (
    <button id={id} onClick={event} className={props.className}>
      {props.children}
    </button>
  );
};

export default Button;
