/** @format */

import React, { useContext } from "react";
import { useUser } from "../../custom-hooks/useUser";
import { Context } from "../../context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "../Button";
import "./styles.css";

const UpdateName = ({ toggle, currentLang, profilePage }) => {
  const { state } = useContext(Context);

  let user = useUser();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(profilePage.first_name_required[currentLang]),
    lastName: Yup.string().required(profilePage.last_name_required[currentLang]),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // On submit function fired when form submission is made
  function onSubmit(data) {}

  const saveName = () => {
    const data = getValues();
    if (!data.firstName || !data.lastName) {
      handleSubmit(onSubmit);
      return;
    }
    user.updateName(
      data.firstName,
      data.lastName,
      state.userDetails.user_id,
      state.userDetails.token
    );
    toggle();
  };
  return (
    <form
      id="updateName"
      className="row d-flex justify-content-center"
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      noValidate
      autoComplete="off"
    >
      <div className="form-row col-12 py-3">
        <input
          ref={register}
          name="firstName"
          type="text"
          placeholder={profilePage.first_name[currentLang]}
          className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
        />
        <div className="invalid-feedback form-invalid-message">
          {errors?.firstName?.message}
        </div>
      </div>
      <div className="form-row col-12 py-3">
        <input
          ref={register}
          name="lastName"
          type="text"
          placeholder={profilePage.last_name[currentLang]}
          className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
        />
        <div className="invalid-feedback form-invalid-message">
          {errors?.lastName?.message}
        </div>
      </div>
      <div className="form-row col-10">
        <Button
          event={saveName}
          form="updateName"
          className="btn btn-main btn-main-small"
        >
          {profilePage.update_name[currentLang]}
        </Button>
      </div>
    </form>
  );
};

export default UpdateName;
