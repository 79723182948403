/** @format */

import React, { useContext } from "react";
import { useUser } from "../../custom-hooks/useUser";
import { Context } from "../../context";
import Button from "../../components/Button";
// import TextField from "@material-ui/core/TextField";
import "./styles.css";

const Register = ({ toggle }) => {
  const { state } = useContext(Context);

  let user = useUser();

  const deleteAccount = (userId, token) => {
    user.deleteAccount(userId, token);
    toggle();
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-10">
        <Button
          event={() =>
            deleteAccount(state.userDetails.user_id, state.userDetails.token)
          }
          type="submit"
          className="btn btn-main btn-main-small"
        >
          Delete Account
        </Button>
      </div>
    </div>
  );
};

export default Register;
