/** @format */

import React, { useReducer, createContext } from "react";
import languagesData from "../data/language.json"

function reducer(state, action) {
  switch (action.type) {
    case "USER_DETAILS":
      return {
        ...state,
        userDetails: {
          token: action.payload.token,
          ngrp_id: action.payload.ngrp_id,
          existing_payment: action.payload.existing_payment,
          user_id: action.payload.user_id,
          palm_id: action.payload.palm_id,
          billing_phone: action.payload.billing_phone,
          username: action.payload.username,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
        },
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "SUCCESS":
      return {
        ...state,
        success: action.payload,
      };
    case "LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "ADD_CARD_TOKEN":
      return {
        ...state,
        addCardToken: action.payload,
      };
    case "CARD_ADDED":
      return {
        ...state,
        cardAdded: action.payload,
      };
    case "USER_AUTH":
      return {
        ...state,
        auth: action.payload,
      };
    case "STEP":
      return {
        ...state,
        step: action.payload,
      };
    case "API_ERROR": {
      return {
        ...state,
        apiError: action.payload,
      };
    }
    case "STORE_ADDRESS":
      return {
        ...state,
        storeAddress: action.payload,
      };
    case "LANGUAGE":
      return {
        ...state,
        lang: action.payload,
      };
    case "SMS_RESET":
      return{...state,smsReset: action.payload};
    default:
      return { ...state };
  }
}

const initialState = {
  error: "",
  apiError: "",
  success: "",
  loading: false,
  lang: "en",
  languages: languagesData,
  userDetails: {
    token: "",
    ngrp_id: "",
    existing_payment: false,
    user_id: "",
    palm_id: "",
    billing_phone: "",
    username: "",
    first_name: "",
    last_name: "",
  },
  addCardToken: "",
  cardAdded: false,
  auth: null,
  step: 1,
  storeAddress: {
    store_id: "",
    store_name: "",
    store_address: "",
    store_city: "",
    store_state: "",
    store_postcode: "",
    store_country: "",
  },
  smsReset: {
    email: "",
    id: null,
    token: "",
    code: null,
    success: false
  }
};

// Create a counter context, initially storing the initialState
const Context = createContext(initialState);

// Create a provider to pass down the
const Provider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export { Context, Provider };
