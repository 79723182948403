/** @format */

import React, {useEffect, useContext, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "../../context";
import { useUser } from "../../custom-hooks/useUser";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "../../components/Button";
import "./styles.css";
import Link from "../../components/Link";

const Login = () => {
  const { state } = useContext(Context);

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {signup_signin_page: signinPage} = state.languages;

  // Custom user hook
  let user = useUser();

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .email("Email is invalid"),
    password: Yup.string()
      .required("Original password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Minimum 8 characters with at least one letter, one number and one special character"
      ),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // On submit function fired when form submission is made
  function onSubmit(data) {}
  const handleFormSubmit = async (e) => {
    await handleSubmit(() => {})(e);
    return false;
  };
  // useEffect(() => {
  // 	if (state.auth) {
  // 		if (location.state) {
  // 			history.push(location.state.from.pathname);
  // 		} else {
  // 			history.push("/profile");
  // 		}
  // 	}
  // 	dispatch({
  // 		type: "ERRORS",
  // 		payload: "",
  // 	});
  // }, [state.auth]);

  const login = () => {
    const values = getValues();
    if (
      !values.username ||
      !values.password ||
      Object.keys(errors).length > 0
    ) {
      handleSubmit(onSubmit);
    } else {
      user.login(values.username, values.password);
    }
  };

  return (
    <div className="container register">
      <div className="d-block text-center pt-4 register-heading">
        <h1>{signinPage.signinToPalm[currentLang]}</h1>
      </div>
      <form
        id="login"
        className="row d-flex justify-content-center mt-5"
        onSubmit={(e) => handleFormSubmit(e)}
        onReset={reset}
        noValidate
        autoComplete="off"
      >
        <div className="form-row col-12 py-3">
          {/* <label for="username">
						Use your registered email address
					</label> */}
          <input
            ref={register}
            id="username"
            name="username"
            placeholder={signinPage.user_name[currentLang]}
            type="text"
            className={`form-control ${errors.username ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors.username?.message}
          </div>
        </div>
        <div className="form-row col-12 py-3">
          <input
            ref={register}
            name="password"
            placeholder={signinPage.password[currentLang]}
            type="password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors.password?.message}
          </div>
        </div>
        <div className="form-row col-12 d-grid">
          <Link href="/forgot-password" className="signup-link">
            <small>{signinPage.forgot_password[currentLang]}</small>
          </Link>
        </div>
        <div className="form-row col-10 d-grid">
          <Button
            event={login}
            form="login"
            className="btn btn-main btn-main-lg btn-block mt-4"
          >
            {signinPage.signIn[currentLang]}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
