/** @format */

import React, { useContext } from "react";
import { Context } from "../../context";
import Button from "../Button";
import CloseIcon from "@material-ui/icons/Close";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./styles.css";

const ErrorBanner = () => {
  // Get the context provider
  const { state, dispatch } = useContext(Context);

  const handleClickAway = () => {
    dispatch({
      type: "ERROR",
      payload: "",
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={`error-banner p-3 pb-3 d-flex align-items-center text-white ${
          state.error !== "" ? "slide-in-top" : "slide-out-top"
        }`}
      >
        <Button event={handleClickAway} className={`btn p-0`}>
          <CloseIcon style={{ color: "#fff" }} />
        </Button>
        <p className="m-0 error-banner-message">{state.error}</p>
      </div>
    </ClickAwayListener>
  );
};

export default ErrorBanner;
