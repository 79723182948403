/** @format */

import React, {useContext, useState, useEffect} from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"
import IconButton from "@material-ui/core/IconButton"
import Button from "../../../components/Button";
import {useUser} from "../../../custom-hooks/useUser";
import {Context} from "../../../context";

const NewPassword = () => {
    let history = useHistory();
    const user = useUser();
    const {state} = useContext(Context);

    const { smsReset,languages } = state;
    const { email: resetEmail,id: resetId,token: resetToken } = smsReset;
    const {smsChange, clearStateLogin} = user;
    const [currentLang, setCurrentLang] = useState(state.lang);
    const {signup_signin_page: signinPage,submit: submitText} = languages;

    useEffect(() => {
        setCurrentLang(state.lang);
    }, [state.lang]);


    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                signinPage.minimum_8_characters[currentLang]
            ),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Password confirm is required"),
    })

    // On submit function fired when form submission is made
    function onSubmit(data) {
        smsChange(resetEmail, data.password, resetToken, resetId)
    }

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, errors } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    return !smsReset.success ? (
        <div className="row d-flex justify-content-center">
            <form
                id="changePassword"
                className="row d-flex justify-content-center col-12 col-md-12 col-lg-8 m-auto"
                onSubmit={handleSubmit(onSubmit)}
                onReset={reset}
                noValidate
                autoComplete="off"
            >
                <div className="form-group b-block">
                    <Tooltip
                        placement="top"
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                        arrow
                        title={signinPage.minimum_8_characters_explanation[currentLang]}
                    >
                        <IconButton
                            aria-label="Information"
                            style={{ paddingTop: "6px" }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                    <input
                        name="password"
                        label="New password"
                        type="password"
                        placeholder={signinPage.new_password}
                        ref={register}
                        className={`form-control ${
                            errors.password ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback form-invalid-message">
                        {errors.password?.message}
                    </div>
                </div>
                <div className="form-group b-block">
                    <input
                        name="passwordConfirm"
                        label={signinPage.repeat_new_password[currentLang]}
                        type="password"
                        placeholder={signinPage.repeat_new_password[currentLang]}
                        ref={register}
                        className={`form-control ${
                            errors.passwordConfirm ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback form-invalid-message">
                        {errors.passwordConfirm?.message}
                    </div>
                </div>
                <div className="form-row col-10 col-xs-8 col-lg-10 d-grid">
                    <button
                        type="submit"
                        form="changePassword"
                        className="btn btn-main btn-main-lg btn-block mt-4"
                    >
                        {signinPage.continue[currentLang]}
                    </button>
                </div>
            </form>
        </div>
    ) : (
        <div className="row d-flex justify-content-center">
            <div className="col-12 password-reset-message text-center">
                <p className="mb-1">
                    {signinPage.password_has_been_reset[currentLang]}
                </p>
                <p>Please go back to Login.</p>
                <div className="form-row col-10 d-grid">
                    <Button
                        event={() => clearStateLogin(history)}
                        className="btn btn-main btn-main-lg btn-block mt-4"
                    >
                        {signinPage.login[currentLang]}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NewPassword;

