/** @format */

export const checkProperties = (obj) => {
  for (let key in obj) {
    if (obj[key] === "") return false;
  }
  return true;
};

export const reformatPhone = (phone) => {
  let x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
};
