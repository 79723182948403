/** @format */

import React, {useContext, useEffect, useState} from "react";
import { useUser } from "../../custom-hooks/useUser";
import { Context } from "../../context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { reformatPhone } from "../../utils/validations";
import Button from "../../components/Button";
import "./styles.css";

const Register = () => {
  const { state } = useContext(Context);

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {signup_signin_page: signinPage} = state.languages;

  // Custom user hook
  let user = useUser();

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);

  // Regex for evaluating the phone number
  const phoneRegExp = /^\(\d{3}\)[ ]\d{3}[-]\d{4}$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Original password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Minimum 8 characters with at least one letter, one number and one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirm is required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, watch, setValue, errors, getValues } =
    useForm({
      resolver: yupResolver(validationSchema),
      mode: "all",
    });

  // On submit function fired when form submission is made
  function onSubmit(data) {}
  const handleFormSubmit = async (e) => {
    await handleSubmit(() => {})(e);
    return false;
  };

  const onRegister = () => {
    const data = getValues();
    if (!data.email || !data.firstName || Object.keys(errors).length > 0) {
      handleSubmit(onSubmit);
    } else {
      let number = data.phone.replace(/[^A-Z0-9]/gi, "");
      user.register({
        username: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        billing_phone: number,
        password: data.confirmPassword,
      });
    }
  };

  const watchPhone = watch("phone", false);

  useEffect(() => {
    if (watchPhone && watchPhone !== "") {
      setValue("phone", reformatPhone(watchPhone), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watchPhone]);

  return (
    <div className="container register">
      <div className="d-block text-center pt-4 register-heading">
        <h1>{signinPage.welcome_text[currentLang]}</h1>
        <p>{signinPage.lets_get_started[currentLang]}</p>
      </div>
      <form
        id="register"
        className="row d-flex justify-content-center"
        onSubmit={(e) => handleFormSubmit(e)}
        onReset={reset}
        noValidate
        autoComplete="off"
      >
        <div className="form-row col-12 py-3">
          <input
            ref={register}
            name="firstName"
            type="text"
            placeholder={signinPage.first_name[currentLang]}
            className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors.firstName?.message}
          </div>
        </div>
        <div className="form-row col-12 py-3">
          <input
            ref={register}
            name="lastName"
            type="text"
            placeholder={signinPage.last_name[currentLang]}
            className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors.lastName?.message}
          </div>
        </div>
        <div className="form-row col-12 py-3">
          <input
            ref={register}
            name="email"
            placeholder={signinPage.email[currentLang]}
            type="text"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors.email?.message}
          </div>
        </div>
        <div className="form-row col-12 py-md-3">
          <div className="row">
            <div className="col-12 col-md-6 py-3 py-md-0">
              <input
                ref={register}
                name="password"
                placeholder={signinPage.password[currentLang]}
                type="password"
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback form-invalid-message">
                {errors.password?.message}
              </div>
            </div>
            <div className="col-12 col-md-6 py-3 py-md-0">
              <input
                ref={register}
                name="confirmPassword"
                placeholder={signinPage.confirm_password[currentLang]}
                type="password"
                className={`form-control ${
                  errors.confirmPassword ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback form-invalid-message">
                {errors.confirmPassword?.message}
              </div>
            </div>
          </div>
        </div>
        <div className="form-row col-12 py-3">
          <input
            ref={register}
            name="phone"
            type="phone"
            placeholder={signinPage.phone_number[currentLang]}
            className={`form-control ${errors.phone ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors.phone?.message}
          </div>
        </div>
        <div className="form-row col-10 d-grid">
          <Button
            event={onRegister}
            form="register"
            className="btn btn-main btn-main-lg btn-block mt-4"
          >
            {signinPage.continue[currentLang]}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Register;
