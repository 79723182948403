/** @format */
import React from "react";
import { Provider } from "./context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TopBar from "./components/TopBar";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import AddPayment from "./pages/AddPayment";
import ErrorBanner from "./components/ErrorBanner";
import Loader from "./components/Loader";
import AppWrapper from "./components/AppWrapper";
import Auth from "./components/Auth";
import Profile from "./pages/Profile";
import Wallet from "./pages/Wallet";
import Transactions from "./pages/Transactions";
import GetStarted from "./pages/GetStarted";
import "./App.css";
import SmsReset from "./pages/ForgotPassword/SmsReset";

function App() {
  return (
    <Provider>
      <div className="App">
        <AppWrapper>
          <Router>
            <TopBar />
            <ErrorBanner />
            <Loader />
            <div className="app-body h-100">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/register">
                  <Register />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/forgot-password">
                  <SmsReset />
                </Route>
                <Route path="/add-payment">
                  <Auth>
                    <AddPayment />
                  </Auth>
                </Route>
                <Route path="/profile">
                  <Auth>
                    <Profile />
                  </Auth>
                </Route>
                <Route path="/wallet">
                  <Auth>
                    <Wallet />
                  </Auth>
                </Route>
                <Route path="/transactions">
                  <Auth>
                    <Transactions />
                  </Auth>
                </Route>
                <Route path="/get-started">
                  <Auth>
                    <GetStarted />
                  </Auth>
                </Route>
              </Switch>
            </div>
          </Router>
        </AppWrapper>
      </div>
    </Provider>
  );
}

export default App;
