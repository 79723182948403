/** @format */

import React, { useContext, useEffect } from "react";
import { Context } from "../../context";
import Cookies from "js-cookie";

const AppWrapper = (props) => {
  // Get the context provider
  const { state, dispatch } = useContext(Context);

  // Check if any values are missing from the currentUser object and get cookie values instead
  const validateObj = (obj) => {
    for (let key in obj) {
      if (obj[key] === "") return false;
    }
    return true;
  };

  useEffect(() => {
    if ((!state.userDetails || !state.userDetails.token) &&
      Cookies.get("userDetails") !== undefined
    ) {
      let user = JSON.parse(Cookies.get("userDetails"));
      dispatch({
        type: "USER_DETAILS",
        payload: {
          token: user.token,
          ngrp_id: user.ngrp_id,
          palm_id: user.palm_id || "",
          existing_payment: user.existing_payment,
          user_id: user.user_id,
          billing_phone: user.billing_phone,
          username: user.username,
          first_name: user.first_name,
          last_name: user.last_name,
        },
      });
      dispatch({
        type: "USER_AUTH",
        payload: true,
      });
    }
    if ((!state.userDetails || !state.userDetails.token) &&
      Cookies.get("userDetails") === undefined
    ) {
      dispatch({
        type: "USER_AUTH",
        payload: false,
      });
    }
  }, [state.userDetails]);

  return props.children;
};

export default AppWrapper;
