/** @format */

export const API_URL = process.env.REACT_APP_API_URL;
export const ORDERS_API_URL = process.env.REACT_APP_ORDERS_API_URL;

// Universal
export const API_KEY = process.env.REACT_APP_API_KEY;
export const AUTH =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjExLCJpYXQiOjE1OTA1MTY5OTI0MDAsInRhYmxlIjoiaXBjX2N1c3RvbWVycyJ9.s6Z80vJpAhFU2FK4_0WcHly23tKyPOEp6oWVWmdF6ig";
export const AUTH1 =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEwMDAwMDEwNjcsImlhdCI6MTYxODMzMjI0MjM0NCwidGFibGUiOiJpcGNfY3VzdG9tZXJzIiwiZXhwIjoxNjIzNTE2MjQyfQ.6ob9lpWAG7Zc0vjsFdn4SiDLFZw1kLZSvoXtr6RtJ00";
