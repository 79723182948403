/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { useUser } from "../../custom-hooks/useUser";
import Button from "../../components/Button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./styles.css";
import axios from "axios";
import { ORDERS_API_URL } from "../../environment";

const Transactions = () => {
  /* Destructure the state and dispatch values from the Context object
       passed as "value" to the Context.Provider in store.js
    */
  const { state, dispatch } = useContext(Context);
  const [orderDetails, setOrderDetails] = useState([]);

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {transaction_page: transactionPage} = state.languages;

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);

  useEffect(() => {
    if (state.userDetails.user_id !== "" && state.userDetails.token !== "") {
      axios
        .get(`${ORDERS_API_URL}/orders`, {
          headers: {
            Authorization: state.userDetails.token,
          },
        })
        .then((res) => {
          setOrderDetails(res.data.content);
        })
        .catch((err) => {
          dispatch({
            type: "ERRORS",
            payload: "Could not get transaction data!",
          });
        });
    }
  }, [state.userDetails.user_id, state.userDetails.token]);

  return (
    <div className="container wallet">
      <div className="d-block text-center pt-4 register-heading">
        <h1>{transactionPage.your_transactions[currentLang]}</h1>
      </div>
      {orderDetails.length ? (
        <div className="col-12">
          {orderDetails.map((val, key) => {
            return (
              <div className="row">
                <div key={key} className="col-12">
                  <div className="border-top-grey py-2">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="row m-0 py-2 w-100">
                          <div className="col-7 p-0 text-left">
                            <p>{val.order}</p>
                            <p>{val.date}</p>
                            <p className="m-0 order-price-text">{`$ ${val.total}`}</p>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {val.receipt &&
                          val.receipt.length &&
                          val.receipt.map((detail) => (
                            <div className="row m-0 border-top-grey py-2 w-100">
                              <div className="col-3 p-0 text-left">
                                <img
                                  src={detail.img}
                                  alt="image"
                                  width="90px"
                                />
                              </div>
                              <div className="col-9">
                                <div className="row">
                                  <div className="col-5 text-left">
                                    <span
                                        style={{
                                          fontSize: "1rem",
                                          display: "block",
                                          lineHeight: "1.5",
                                          paddingTop: 25,
                                        }}
                                    >
                                      {detail.title}
                                    </span>
                                  </div>
                                  <div className="col-7 p-0 text-right d-flex align-items-end justify-content-center flex-column">
                                    <b>{`${detail.price > 0 ? "$" : ""} ${
                                        Number.parseFloat(detail.price).toFixed(2)
                                    }`}</b>
                                  </div>
                                </div>
                                {detail.bottle_fee && (
                                    <div className="row">
                                      <div className="col-5 text-left" style={{ color: "#838383" }}>
                                        {transactionPage.bottle_deposit_fee[currentLang]}
                                      </div>
                                      <div className="col-7 p-0 text-right d-flex align-items-end justify-content-center flex-column" style={{ color: "#838383" }}>
                                        {`${
                                            detail.bottle_fee > 0 ? "$" : ""
                                        } ${Number.parseFloat(detail.bottle_fee).toFixed(2)}`}
                                      </div>
                                    </div>
                                )}
                                {detail.coupon && (
                                    <div className="row">
                                      <div className="col-5 text-left" style={{ color: "#838383" }}>
                                        {transactionPage.coupon[currentLang]}
                                      </div>
                                      <div className="col-7 p-0 text-right d-flex align-items-end justify-content-center flex-column" style={{ color: "#838383" }}>
                                        {`${
                                            detail.coupon ? "$" : ""
                                        } ${Number.parseFloat(detail.coupon).toFixed(2)}`}
                                      </div>
                                    </div>
                                )}
                                {detail.discount && (
                                    <div className="row">
                                      <div className="col-5 text-left" style={{ color: "#838383" }}>  
                                        Discount
                                      </div>
                                      <div className="col-7 p-0 text-right d-flex align-items-end justify-content-center flex-column" style={{ color: "#838383" }}>
                                        {`${
                                            detail.discount > 0 ? "$" : ""
                                        } ${Number.parseFloat(detail.discount).toFixed(2)}`}
                                      </div>
                                    </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </AccordionDetails>
                      <AccordionDetails>
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <div className="row">
                            <div className="col-9 col-sm-10">{transactionPage.subtotal[currentLang]}</div>
                            <div className="col-3 col-sm-2">{`$ ${Number.parseFloat(val.sub_total).toFixed(2)}`}</div>
                          </div>
                          <div className="row">
                            <div className="col-9 col-sm-10">{transactionPage.tax[currentLang]}</div>
                            <div className="col-3 col-sm-2">{`${
                              val.tax > 0 ? "$" : ""
                            } ${Number.parseFloat(val.tax).toFixed(2)}`}</div>
                          </div>
                          <div className="row">
                            <div className="col-9 col-sm-10">{transactionPage.discount[currentLang]}</div>
                            <div className="col-3 col-sm-2">{`${
                              val.discount > 0 ? "$" : ""
                            } ${Number.parseFloat(val.discount).toFixed(2)}`}</div>
                          </div>
                          <div className="row">
                            <b className="col-9 col-sm-10">{transactionPage.total[currentLang]}</b>
                            <b className="col-3 col-sm-2">{`$ ${Number.parseFloat(val.total).toFixed(2)}`}</b>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="d-block text-center pt-4 register-heading">
          <p className="m-0">{transactionPage.no_history[currentLang]}</p>
        </div>
      )}
    </div>
  );
};

export default Transactions;
