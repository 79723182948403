/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { useUser } from "../../custom-hooks/useUser";
import Button from "../Button";
import dropin from "braintree-web-drop-in";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { STATE_NAME } from "../../utils/constants";

import "./styles.css";

const BrainTree = ({ ...props }) => {
  const { state } = useContext(Context);
  // form validation rules for STEP ONE of signup
  const [currentLang, setCurrentLang] = useState(state.lang);
  const {braintree:brainTreePage,continue: continueText} = state.languages;

  const validationSchema = Yup.object().shape({
    streetAddress: Yup.string().required(brainTreePage.street_address_required[currentLang]),
    region: Yup.string().required(brainTreePage.state_required[currentLang]),
    locality: Yup.string().required(brainTreePage.city_address_required[currentLang]),
    postalCode: Yup.string().required(brainTreePage.postal_required[currentLang]),
    nameOnCard: Yup.string().required(brainTreePage.name_on_card_required[currentLang])
  });

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);

  const { getValues, register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Add dropin destroy when there is an error adding a credit card.
  const [dropinInstance, setDropinInstance] = useState(null);

  // Disable/Enable the add credit card button
  const [disableAdd, setDisableAdd] = useState(false);

  const [showBrainTree, setShowBrainTree] = useState(false);

  useEffect(() => {
    setShowBrainTree(true);
  }, []);

  useEffect(() => {
    if (state.addCardToken && showBrainTree) {
      dropin.create(
        {
          authorization: state.addCardToken,
          selector: "#dropin-container",
          vaultManager: true,
          dataCollector: true,
          card: {
            overrides: {
              fields: {
                postalCode: null,
              },
            },
          },
        },
        function (err, dropinInstance) {
          if (err) {
            // Handle any errors that might've occurred when creating Drop-in
            return;
          }
          setDropinInstance(dropinInstance);
        }
      );
    }
  }, [state.addCardToken, showBrainTree]);

  const callBrainTreeAPI = () => {
    const values = getValues();
    if ((values.streetAddress.length === 0 || !values.region || !values.locality || !values.postalCode || !values.nameOnCard)
        || Object.keys(errors).length > 0) {
      handleSubmit(() => {})();
      return;
    }
    setDisableAdd(true);
    dropinInstance.requestPaymentMethod(function (err, payload) {
      if (err) {
        return;
      }
      props.onAddNewCard({ ...payload, ...getValues() });
      setDisableAdd(false);
    });
  };

  const handleFormSubmit = async (e) => {
    await handleSubmit(() => {})(e);
    return false;
  };

  return (
    <div className="row">
      <div className="form-group col-12">
        <h5 className="mb-1 your-information">{brainTreePage.billing_info[currentLang]}</h5>
      </div>
      <form
        id="completeCheckout"
        className="row"
        onSubmit={(e) => handleFormSubmit(e)}
        onReset={reset}
        noValidate
        autoComplete="off"
      >
        <div style={{ paddingTop: 20 }} className="form-group col-12 col-md-12">
          <label htmlFor="nameOnCard">
            <span className="required">*</span>
            {brainTreePage.name_on_card[currentLang]}
          </label>
          <input
            name="nameOnCard"
            type="text"
            id="nameOnCard"
            placeholder={brainTreePage.name_on_card_placeholder[currentLang]}
            ref={register}
            className={`form-control ${errors?.nameOnCard ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors?.nameOnCard?.message}
          </div>
        </div>
        <div style={{ paddingTop: 20 }} className="form-group col-12 col-md-6">
          <label htmlFor="streetAddress">
            <span className="required">*</span>
            {brainTreePage.street_address[currentLang]}
          </label>
          <input
            name="streetAddress"
            type="text"
            id="streetAddress"
            placeholder={brainTreePage.street_address[currentLang]}
            ref={register}
            className={`form-control ${
              errors?.streetAddress ? "is-invalid" : ""
            }`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors?.streetAddress?.message}
          </div>
        </div>
        <div style={{ paddingTop: 20 }} className="form-group col-12 col-md-6">
          <label htmlFor="locality">
            <span className="required">*</span>
            {brainTreePage.city[currentLang]}
          </label>
          <input
            name="locality"
            type="text"
            id="locality"
            placeholder={brainTreePage.city[currentLang]}
            ref={register}
            className={`form-control ${errors?.locality ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors?.locality?.message}
          </div>
        </div>
        <div style={{ paddingTop: 20 }} className="form-group col-12 col-md-6">
          <label htmlFor="region">
            <span className="required">*</span>
            {brainTreePage.state[currentLang]}
          </label>
          <select
            name={"region"}
            id={"region"}
            placeholder={brainTreePage.state[currentLang]}
            ref={register}
            className={`form-control ${errors?.region ? "is-invalid" : ""}`}
          >
            <option value={""}>{brainTreePage.select_state[currentLang]}</option>
            {STATE_NAME.map((sName) => (
              <option value={sName}>{sName}</option>
            ))}
          </select>
          <div className="invalid-feedback form-invalid-message">
            {errors?.region?.message}
          </div>
        </div>
        <div style={{ paddingTop: 20 }} className="form-group col-12 col-md-6">
          <label htmlFor="postalCode">
            <span className="required">*</span>
            {brainTreePage.postal_code[currentLang]}
          </label>
          <input
            name="postalCode"
            type="text"
            id="postalCode"
            placeholder={brainTreePage.postal_code[currentLang]}
            ref={register}
            className={`form-control ${errors?.postalCode ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback form-invalid-message">
            {errors?.postalCode?.message}
          </div>
        </div>
      </form>
      <div className="braintree">
        <div className="col-12 text-center">
          <div id="dropin-container" className="dropin-component"></div>
        </div>
        <div className="col-12 text-center">
          <Button
            id="bt-submit-button"
            className="btn btn-main btn-main-lg btn-block mt-4"
            event={callBrainTreeAPI}
            disabled={disableAdd}
          >
            {props.buttonLabel || continueText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BrainTree;
