/** @format */

import React, {useContext, useEffect, useState} from "react";
import { Context } from "../../context";
import { useUser } from "../../custom-hooks/useUser";
import BrainTree from "../../components/BrainTree";
import { useHistory } from "react-router";
import "./styles.css";

const AddPayment = () => {
  /* Destructure the state and dispatch values from the Context object
	   passed as "value" to the Context.Provider in store.js
	*/
  const { state, dispatch } = useContext(Context);
  let history = useHistory();

  let user = useUser();

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {welcome_text,lets_get_started} = state.languages;

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);


  useEffect(() => {
    if (
      state.addCardToken === "" &&
      state.userDetails.user_id !== "" &&
      state.userDetails.token !== ""
    ) {
      user.getPaymentToken(state.userDetails.user_id, state.userDetails.token);
    }
  }, [state.addCardToken, state.userDetails.user_id, state.userDetails.token]);

  useEffect(() => {
    if (state.cardAdded) {
      history.push("/scan");
      dispatch({ type: "CARD_ADDED", payload: false });
    }
  }, [state.cardAdded]);

  const onAddNewCard = (payload) => {
    user.addCard(
      state.userDetails.first_name,
      state.userDetails.last_name,
      state.userDetails.username,
      payload.nonce,
      state.userDetails.user_id,
      state.userDetails.token
    );
  };

  return (
    <div className="container register">
      <div className="d-block text-center pt-4 register-heading">
        <h1>{welcome_text[currentLang]}</h1>
        <p>{lets_get_started[currentLang]}</p>
      </div>
      <div className="d-block" style={{ marginBottom: "20px" }}>
        <BrainTree onAddNewCard={onAddNewCard} />
      </div>
    </div>
  );
};

export default AddPayment;
