/** @format */

import React, { useContext, useEffect } from "react";
import { useUser } from "../../custom-hooks/useUser";
import { Context } from "../../context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "../Button";
// import TextField from "@material-ui/core/TextField";
import "./styles.css";

const UpdateEmail = ({ toggle,currentLang, profilePage }) => {
  const { state } = useContext(Context);

  let user = useUser();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(profilePage.email_required[currentLang]).email(profilePage.email_invalid),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // On submit function fired when form submission is made
  function onSubmit(data) {}

  const saveEmail = () => {
    const data = getValues();
    if (!data.email) {
      handleSubmit(onSubmit);
      return;
    }
    user.updateEmail(
      state.userDetails.username,
      data.email,
      state.userDetails.user_id,
      state.userDetails.token
    );
    toggle();
  };

  return (
    <form
      id="updateEmail"
      className="row d-flex justify-content-center"
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      noValidate
      autoComplete="off"
    >
      <div className="form-row col-12 py-3">
        <input
          ref={register}
          name="email"
          placeholder={profilePage.email_address[currentLang]}
          type="text"
          className={`form-control ${errors.email ? "is-invalid" : ""}`}
        />
        <div className="invalid-feedback form-invalid-message">
          {errors?.email?.message}
        </div>
      </div>
      <div className="form-row col-10">
        <Button
          event={saveEmail}
          form="updateEmail"
          className="btn btn-main btn-main-small"
        >
          {profilePage.update_email[currentLang]}
        </Button>
      </div>
    </form>
  );
};

export default UpdateEmail;
