/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { useUser } from "../../custom-hooks/useUser";
import Button from "../../components/Button";
import AddCreditCard from "./AddCreditCard";
import axios from "axios";
import { API_URL } from "../../environment";
import EditBillingAddress from "./EditBillingAddress";

import "./styles.css";

const Wallet = () => {
  /* Destructure the state and dispatch values from the Context object
       passed as "value" to the Context.Provider in store.js
    */
  const { state, dispatch } = useContext(Context);
  const [cardInfo, setCardInfo] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  let user = useUser();

    const [currentLang, setCurrentLang] = useState(state.lang);
    const {edit:editText,remove: removeText,wallet_page: walletPage} = state.languages;

    useEffect(() => {
        setCurrentLang(state.lang);
    }, [state.lang]);

  const getCardInfo = () => {
    const { userDetails } = state;
    dispatch({
      type: "LOADING",
      payload: true,
    });
    axios
      .get(`${API_URL}/payment/card_info/${userDetails.user_id}`, {
        headers: {
          Authorization: userDetails.token,
        },
      })
      .then(
        (res) => {
          const cardInfoList = [];
          res.data.map((card) => {
            const billingInfo = card.billing_info
              ? JSON.parse(card.billing_info)
              : {};
            cardInfoList.push({ ...card, billing_info: billingInfo });
          });
          setCardInfo(cardInfoList);
          dispatch({
            type: "LOADING",
            payload: false,
          });
        },
        () => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      );
  };

  const setAsDefault = (cardDetail) => {
    const { userDetails } = state;
    dispatch({
      type: "LOADING",
      payload: true,
    });
    // https://ckpmstage.interplay.iterate.ai/api/payment/primary-card/:id
    axios
      .post(
        `${API_URL}/payment/primary-card/${userDetails.user_id}`,
        {
          last_4: cardDetail.last_4_digits,
        },
        {
          headers: {
            Authorization: userDetails.token,
          },
        }
      )
      .then(
        (res) => {
          getCardInfo();
        },
        (res) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      );
  };

  const removeCard = (cardDetail) => {
    const { userDetails } = state;
    dispatch({
      type: "LOADING",
      payload: true,
    });
    // https://ckpmstage.interplay.iterate.ai/api/payment/delete-card/:id
    axios
      .post(
        `${API_URL}/payment/delete-card/${userDetails.user_id}`,
        {
          last_4: cardDetail.last_4_digits,
        },
        {
          headers: {
            Authorization: userDetails.token,
          },
        }
      )
      .then(
        (res) => {
          getCardInfo();
        },
        (res) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      );
  };

  const editBillingAddress = (
    nameOnCard,
    streetAddress,
    locality,
    region,
    postalCode
  ) => {
    const { userDetails } = state;
    dispatch({
      type: "LOADING",
      payload: true,
    });
    axios
      .put(
        `${API_URL}/payment/billing-info/${selectedCard.payment_cust_id}`,
        {
          billing_name: nameOnCard,
          billing_address: streetAddress,
          billing_city: locality,
          billing_state: region,
          billing_postcode: postalCode,
        },
        {
          headers: {
            Authorization: userDetails.token,
          },
        }
      )
      .then(
        (res) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          if (res.data.updated) {
            getCardInfo();
            setSelectedCard(null);
          }
        },
        () => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
        }
      );
  };

  useEffect(() => {
    if (state.userDetails.user_id) {
      getCardInfo();
    }
  }, [state.userDetails]);

  useEffect(() => {
    if (state.cardAdded) {
      getCardInfo();
      dispatch({ type: "CARD_ADDED", payload: false });
    }
  }, [state.cardAdded]);

  const onAddNewCard = (payload) => {
    user.addCard(
      state.userDetails.first_name,
      state.userDetails.last_name,
      state.userDetails.username,
      payload.nonce,
      state.userDetails.user_id,
      state.userDetails.token,
      payload
    );
  };

  const openEditModal = (selectedCard) => {
    setSelectedCard(selectedCard);
  };

  return (
    <div className="container wallet">
      <div className="d-block text-center pt-4 register-heading">
        <h1>Your Wallet</h1>
      </div>
      {cardInfo.map((card) => {
        const { billing_info: billingInfo, card_info, is_default } = card;

        return (
          <div className="row">
            <div className="col-12">
              <div className="d-block border-bottom-grey py-4">
                <h2 className="profile-title">
                  {card_info.card_type} ****
                  {card_info.last_4_digits}
                </h2>
                <p className="profile-data mb-2">{`${
                  billingInfo.first_name || ""
                } ${billingInfo.last_name || ""}`}</p>
                <p className="profile-data m-0">{walletPage.expires[currentLang]} {card_info.expire}</p>
                {is_default ? (
                  <Button className="btn default-card p-0">
                      {walletPage.default_payment_method[currentLang]}
                  </Button>
                ) : (
                  <Button
                    className="btn default-card p-0"
                    event={() => setAsDefault(card_info)}
                  >
                    <u>{walletPage.set_default_payment_method[currentLang]}</u>
                  </Button>
                )}
                <div className="d-block mt-3">
                  <Button
                    className="btn btn-outline-secondary btn-sm me-3"
                    event={() => openEditModal(card)}
                  >
                      {editText[currentLang]}
                  </Button>
                  <Button
                    className="btn btn-outline-secondary btn-sm"
                    event={() => removeCard(card_info)}
                  >
                      {removeText[currentLang]}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {selectedCard && (
        <EditBillingAddress
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          editBillingAddress={editBillingAddress}
        />
      )}
      {!state.cardAdded && <AddCreditCard onAddNewCard={onAddNewCard} />}
    </div>
  );
};

export default Wallet;
