/** @format */

import React, { useContext } from "react";
import { useUser } from "../../custom-hooks/useUser";
import { Context } from "../../context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Button from "../Button";
// import TextField from "@material-ui/core/TextField";
import "./styles.css";

const UpdatePassword = ({ toggle, currentLang, profilePage }) => {
  const { state } = useContext(Context);

  let user = useUser();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(profilePage.password_required[currentLang])
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        profilePage.minimum_8_characters[currentLang]
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], profilePage.password_match[currentLang])
      .required(profilePage.confirm_password_required[currentLang]),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // On submit function fired when form submission is made
  function onSubmit(data) {}

  const savePassword = () => {
    const data = getValues();
    if (!data.confirmPassword) {
      handleSubmit(onSubmit);
      return;
    }
    user.updatePassword(
      state.userDetails.username,
      data.confirmPassword,
      state.userDetails.user_id,
      state.userDetails.token
    );
    toggle();
  };

  return (
    <form
      id="updatePassword"
      className="row d-flex justify-content-center"
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      noValidate
      autoComplete="off"
    >
      <div className="form-row col-12 py-3">
        <input
          ref={register}
          name="password"
          placeholder={profilePage.password[currentLang]}
          type="password"
          className={`form-control ${errors.password ? "is-invalid" : ""}`}
        />
        <div className="invalid-feedback form-invalid-message">
          {errors?.password?.message}
        </div>
      </div>
      <div className="form-row col-12 py-3">
        <input
          ref={register}
          name="confirmPassword"
          placeholder={profilePage.confirm_password[currentLang]}
          type="password"
          className={`form-control ${
            errors.confirmPassword ? "is-invalid" : ""
          }`}
        />
        <div className="invalid-feedback form-invalid-message">
          {errors?.confirmPassword?.message}
        </div>
      </div>
      <div className="form-row col-10">
        <Button
          event={savePassword}
          form="updatePassword"
          className="btn btn-main btn-main-small mt-3"
        >
          {profilePage.update_password[currentLang]}
        </Button>
      </div>
    </form>
  );
};

export default UpdatePassword;
