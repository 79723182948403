import {useContext, useEffect, useState} from "react";
import BrainTree from "../../../components/BrainTree";
import { Context } from "../../../context";
import { useUser } from "../../../custom-hooks/useUser";
import "./styles.css";

const AddCreditCard = ({ ...props }) => {
  /* Destructure the state and dispatch values from the Context object
       passed as "value" to the Context.Provider in store.js
    */
  const { state, dispatch } = useContext(Context);

  let user = useUser();

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {braintree:brainTreePage} = state.languages;

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);

  useEffect(() => {
    if (
      state.addCardToken === "" &&
      state.userDetails.user_id !== "" &&
      state.userDetails.token !== ""
    ) {
      user.getPaymentToken(state.userDetails.user_id, state.userDetails.token);
    }
  }, [state.addCardToken, state.userDetails.user_id, state.userDetails.token]);

  const onAddNewCard = (payload) => {
    props.onAddNewCard(payload);
  };

  return (
    <div className="row braintree-profile-container">
      <div className="col-12 text-center">
        <p className="edit-profile-label mt-4 mb-0">
          {`${brainTreePage.add_additional_credit[currentLang]}:`}
        </p>
      </div>
      {state.addCardToken && (
        <BrainTree buttonLabel={brainTreePage.add_credit_card[currentLang]} onAddNewCard={onAddNewCard} />
      )}
    </div>
  );
};

export default AddCreditCard;
