/** @format */

import React from "react";
import ReactDOM from "react-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "./styles.css";

const Modal = ({
  isShowing,
  modalName,
  name,
  hide,
  title,
  allowClose,
  height,
  ...props
}) =>
  isShowing && name === modalName
    ? ReactDOM.createPortal(
        <>
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div
              className={`page-modal ${props.className ? props.className : ""}`}
            >
              {allowClose && (
                <button
                  type="button"
                  className="button-close-modal"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => hide(name)}
                >
                  <ArrowBackIosIcon />
                </button>
              )}

              <div className="modal-body text-center p-0">
                {title ? (
                  <h6 className="page-modal-title mb-2">{title}</h6>
                ) : null}
                {props.children}
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;

export default Modal;
