/** @format */

import React from "react";
import PaginationBullet from "../PaginationBullet";
import "./styles.css";

const Pagination = ({ step, get_started,currentLang }) => {
  return (
    <div className="row pagination d-flex justify-content-between py-3">
      <div className="pagination-line"></div>
      <PaginationBullet step={step} stepNum={1} title={get_started.find_a_store[currentLang]} />
      <PaginationBullet step={step} stepNum={2} title={get_started.enter_code[currentLang]} />
      <PaginationBullet step={step} stepNum={3} title={get_started.complete_setup[currentLang]} />
    </div>
  );
};

export default Pagination;
