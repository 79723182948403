/** @format */

import React, { useContext, useEffect } from "react";
import { useUser } from "../../custom-hooks/useUser";
import { Context } from "../../context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { reformatPhone } from "../../utils/validations";
import Button from "../Button";
// import TextField from "@material-ui/core/TextField";
import "./styles.css";

const UpdatePhone = ({ toggle, currentLang, profilePage }) => {
  const { state } = useContext(Context);

  let user = useUser();

  // Regex for evaluting the phone number
  const phoneRegExp = /^\(\d{3}\)[ ]\d{3}[-]\d{4}$/;

  const validationSchema = Yup.object().shape({
    phone: Yup.string().matches(phoneRegExp, profilePage.invalid_phone[currentLang]),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, watch, setValue, errors, getValues } =
    useForm({
      resolver: yupResolver(validationSchema),
      mode: "all",
    });

  // On submit function fired when form submission is made
  function onSubmit(data) {}

  const watchPhone = watch("phone", false);

  useEffect(() => {
    if (watchPhone && watchPhone !== "") {
      setValue("phone", reformatPhone(watchPhone), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [watchPhone]);

  const savePhoneNumber = () => {
    const data = getValues();
    if (!data.phone) {
      handleSubmit(onSubmit);
      return;
    }
    let number = data.phone.replace(/[^A-Z0-9]/gi, "");
    user.updatePhone(
      number,
      state.userDetails.user_id,
      state.userDetails.token
    );
    toggle();
  };

  return (
    <form
      id="updatePhone"
      className="row d-flex justify-content-center"
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      noValidate
      autoComplete="off"
    >
      <div className="form-row col-12 py-3">
        <input
          ref={register}
          name="phone"
          type="phone"
          placeholder={profilePage.phone_number[currentLang]}
          className={`form-control ${errors.phone ? "is-invalid" : ""}`}
        />
        <div className="invalid-feedback form-invalid-message">
          {errors?.phone?.message}
        </div>
      </div>
      <div className="form-row col-10">
        <Button
          event={savePhoneNumber}
          form="updatePhone"
          className="btn btn-main btn-main-small"
        >
          {profilePage.update_phone[currentLang]}
        </Button>
      </div>
    </form>
  );
};

export default UpdatePhone;
