/** @format */

import React, {Suspense, useContext, useEffect, useState} from "react"
import {Context} from "../../../context";
import EnterEmail from "../EnterEmail";
import EnterCode from "../EnterCode";
import NewPassword from "../NewPassword";

const SmsReset = () => {
    const { state } = useContext(Context);
    const {smsReset} = state;
    const {email:resetEmail,code:resetCode,token:resetToken,id:resetId}= smsReset;
    const [currentLang, setCurrentLang] = useState(state.lang);
    const {signup_signin_page: signinPage,submit: submitText} = state.languages;

    useEffect(() => {
        setCurrentLang(state.lang);
    }, [state.lang]);

    return (
        <div className="signUp-container container">
            <Suspense fallback={<div>Loading...</div>}>
                <div className="row d-flex justify-content-center login-page">
                    <div className="col-12 pt-3">
                        <div className="row">
                            <div className="col-12 pt-3 px-0 text-center">
                                <h1 className="signin-heading mb-3 pb-2">
                                    {signinPage.reset_password[currentLang]}
                                </h1>
                            </div>
                        </div>
                        {resetEmail === "" && resetCode === null ? (
                            <EnterEmail />
                        ) : resetToken === "" && resetId === null ? (
                            <EnterCode />
                        ) : (
                            <NewPassword />
                        )}
                    </div>
                </div>
            </Suspense>
        </div>
    )
}

export default SmsReset;
