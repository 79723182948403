/** @format */

import React from "react";
import "./styles.css";

const PaginationBullet = ({ step, stepNum, title }) => {
  return (
    <div className="col-3 pagination-bullet text-center">
      <div
        className={`step-icon mx-auto d-flex justify-content-center align-items-center ${`step-${stepNum}-icon`} ${
          stepNum < step ? "complete" : ""
        }`}
      >
        <span className="pagination-icon">{stepNum}</span>
      </div>
      <span
        className={`pagination-title mt-2 ${stepNum === step ? "active" : ""} ${
          stepNum < step ? "complete" : ""
        }`}
      >
        {title}
      </span>
    </div>
  );
};

export default PaginationBullet;
