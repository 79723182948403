/** @format */

import React, {useContext, useEffect, useState} from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import {useUser} from "../../../custom-hooks/useUser";
import {Context} from "../../../context";

const EnterEmail = () => {
    const { state } = useContext(Context);

    const [currentLang, setCurrentLang] = useState(state.lang);
    const {signup_signin_page: signinPage,submit: submitText} = state.languages;

    useEffect(() => {
        setCurrentLang(state.lang);
    }, [state.lang]);

    // form validation rules
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required("User email is required")
            .email("Email address is invalid"),
    })
    const user = useUser();

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, errors } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })

    // On submit function fired when form submission is made
    function onSubmit(data) {
        user.smsReset(data.username);
    }

    return (
        <div className="row d-flex justify-content-center">
            <form
                id="smsReset"
                className="row justify-content-center col-12 col-lg-8"
                onSubmit={handleSubmit(onSubmit)}
                onReset={reset}
                noValidate
                autoComplete="off"
            >
                <div className="form-group b-block">
                    <label htmlFor="loginUsername">
                        <span className="required">*</span>{signinPage.user_name[currentLang]}
                    </label>
                    <input
                        name="username"
                        label="User Name (email address)"
                        type="text"
                        placeholder={signinPage.enter_username[currentLang]}
                        ref={register}
                        className={`form-control ${
                            errors.username ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback form-invalid-message">
                        {errors.username?.message}
                    </div>
                </div>
                <div className="form-row col-10 d-grid">
                    <button
                        type="submit"
                        form="smsReset"
                        className="btn btn-main btn-main-lg btn-block mt-4"
                    >
                        {submitText[currentLang]}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EnterEmail;
