/** @format */

import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import { useUser } from "../../custom-hooks/useUser";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import Button from "../Button";
import "./styles.css";

const Navigation = () => {
  // State for the menu open or closed
  const [menuOpen, setMenuOpen] = useState(false);
  const { state } = useContext(Context);

  const [currentLang, setCurrentLang] = useState(state.lang);
  const {top_bar: topBarPage} = state.languages;

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);

  let user = useUser();
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const handleLogout = () => {
    user.logout();
    setMenuOpen(false);
  };
  const listCls = "d-block p-3 p-lg-5 pt-lg-3 pb-lg-0";
  return (
    <Menu
      left
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <ul className="p-0 navigation text-center">
        <li className={listCls}>
          <Link
            onClick={() => setMenuOpen(false)}
            title="Home"
            className="mobile-nav-item"
            to={state.auth !== null && state.auth ? `/get-started` : "/"}
          >
            {topBarPage.home[currentLang]}
            </Link>
        </li>
        {state.auth !== null && state.auth && (
          <>
            <li className={listCls}>
              <Link
                onClick={() => setMenuOpen(false)}
                title="User profile"
                className="mobile-nav-item"
                to={`/profile`}
              >
                {topBarPage.profile[currentLang]}
              </Link>
            </li>
            <li className={listCls}>
              <Link
                onClick={() => setMenuOpen(false)}
                title="User wallet"
                className="mobile-nav-item"
                to={`/wallet`}
              >
                {topBarPage.wallet[currentLang]}
              </Link>
            </li>
            <li className={listCls}>
              <Link
                onClick={() => setMenuOpen(false)}
                title="User transactions"
                className="mobile-nav-item"
                to={`/transactions`}
              >
                {topBarPage.transactions[currentLang]}
              </Link>
            </li>
          </>
        )}
        <li className={listCls}>
          {state.auth !== null && state.auth ? (
            <Button
              event={handleLogout}
              title={topBarPage.logout[currentLang]}
              className="btn mobile-nav-item"
            >
              {topBarPage.logout[currentLang]}
            </Button>
          ) : (
            <Link
              onClick={() => setMenuOpen(false)}
              title={topBarPage.login[currentLang]}
              className="mobile-nav-item"
              to={`/login`}
            >
              {topBarPage.login[currentLang]}
            </Link>
          )}
        </li>
        {state.auth === null ||
          (!state.auth && (
            <li className={listCls}>
              <Link
                onClick={() => setMenuOpen(false)}
                title={topBarPage.register[currentLang]}
                className="mobile-nav-item"
                to={`/register`}
              >
                {topBarPage.register[currentLang]}
              </Link>
            </li>
          ))}
      </ul>
    </Menu>
  );
};

export default Navigation;
