/** @format */

import React from "react";
import "./styles.css";

const Step = ({ title, description, stepNum, step, img, ...props }) => {
  return stepNum === step ? (
    <div className="row step-one">
      <div className="d-block text-center pt-4 register-heading">
        <div>
          {img ? (
            <img src={img} alt="Hands" className="img-fluid text-center" />
          ) : (
            ""
          )}
        </div>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="d-block">{props.children}</div>
    </div>
  ) : null;
};

export default Step;
