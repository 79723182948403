/** @format */

import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { API_KEY, AUTH1, API_URL, ORDERS_API_URL } from "../environment";
import { Context } from "../context";

// Handle user signup api request
const setError = (message) => ({
    type: "ERROR",
    payload: message,
  })
const setLoading = (flag) => ({
    type: "LOADING",
    payload: flag
  })
export const useUser = () => {
  let history = useHistory();
  let location = useLocation();
  // Get the context provider
  const { state, dispatch } = useContext(Context);

  return {
    register: (userData) => {
      // Destructure the user data properties off of the userData argument
      const {
        username,
        first_name,
        last_name,
        email,
        billing_phone,
        password,
      } = userData;
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/customer/signup`,
          {
            username,
            first_name,
            last_name,
            email,
            billing_phone,
            password,
          },
          {
            headers: {
              apikey: API_KEY,
            },
          }
        )
        .then((res) => {
          axios
            .post(
              `${API_URL}/customer/signin`,
              {
                username: username,
                password: password,
              },
              {
                headers: {
                  apikey: API_KEY,
                },
              }
            )
            .then((res) => {
              let response = res.data;
              Cookies.set("userDetails", {
                token: response.token,
                ngrp_id: response.ngrp_id,
                existing_payment: response.existing_payment,
                user_id: response.id,
                billing_phone: response.billing_phone,
                username: response.username,
                first_name: response.first_name,
                last_name: response.last_name,
                palm_id: response.palm_id || "",
              });
              dispatch({
                type: "USER_DETAILS",
                payload: {
                  token: response.token,
                  ngrp_id: response.ngrp_id,
                  existing_payment: response.existing_payment,
                  user_id: response.id,
                  billing_phone: response.billing_phone,
                  username: response.username,
                  first_name: response.first_name,
                  last_name: response.last_name,
                  palm_id: response.palm_id || "",
                },
              });
              dispatch({
                type: "USER_AUTH",
                payload: true,
              });
              dispatch({
                type: "LOADING",
                payload: false,
              });
              history.push("/get-started");
            })
            .catch((err) => {
              dispatch({
                type: "LOADING",
                payload: false,
              });
            });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            dispatch({
              type: "ERROR",
              payload: err.response.data.message,
            });
          } else {
            dispatch({
              type: "ERROR",
              payload: "Unable to reach the server. Please try again later.",
            });
          }
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    login: (username, password) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/customer/signin`,
          {
            username,
            password,
          },
          {
            headers: {
              apikey: "38Qn_bAKiUq0o4wK9FN0zlwYgthXkA",
            },
          }
        )
        .then((res) => {
          let response = res.data;
          Cookies.set("userDetails", {
            token: response.token,
            ngrp_id: response.ngrp_id,
            palm_id: response.palm_id || "",
            existing_payment: response.existing_payment,
            user_id: response.id,
            billing_phone: response.billing_phone,
            username: response.username,
            first_name: response.first_name,
            last_name: response.last_name,
          });
          dispatch({
            type: "USER_DETAILS",
            payload: {
              token: response.token,
              ngrp_id: response.ngrp_id,
              palm_id: response.palm_id || "",
              existing_payment: response.existing_payment,
              user_id: response.id,
              billing_phone: response.billing_phone,
              username: response.username,
              first_name: response.first_name,
              last_name: response.last_name,
            },
          });
          dispatch({
            type: "USER_AUTH",
            payload: true,
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
          if (location.state) {
            history.push(location.state.from.pathname);
          } else {
            if (response.palm_id == null) {
              history.push("/get-started");
              dispatch({
                type: "STEP",
                payload: 1,
              });
            } else history.push("/profile");
          }
        })
        .catch((err) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          dispatch({
            type: "ERROR",
            payload: err.response.data.message,
          });
        });
    },
    smsReset: (email) =>  {
      const smsState = state.smsReset
      dispatch(setLoading(true));
      axios
          .post(
              `${ORDERS_API_URL}/sms_reset`,
              {
                email,
              },
              {
                headers: {
                  apikey: "38Qn_bAKiUq0o4wK9FN0zlwYgthXkA",
                },
              }
          )
          .then((res) => {
            dispatch({
              type: "SMS_RESET",
              payload: { ...smsState, email: res.data.email },
            })
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setError("Email does not exists"))
            //err.response.data.message
            dispatch(setLoading(false));
          })
    },
    smsCheck: (email, code) => {
      const smsState = state.smsReset
      dispatch(setError(""))
      let codeInt = parseInt(code);
      dispatch(setLoading(true));
      axios
          .post(
              `${ORDERS_API_URL}/sms_check`,
              {
                email,
                code: codeInt,
              },
              {
                headers: {
                  apikey: "38Qn_bAKiUq0o4wK9FN0zlwYgthXkA",
                },
              }
          )
          .then((res) => {
            dispatch({
              type: "SMS_RESET",
              payload: {
                ...smsState,
                token: res.data.token,
                id: res.data.id,
              },
            })
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(setError(err.response.status===404?"Could not send code. Please try again.":"Invalid Code."))
            dispatch(setLoading(false));
          })
    },
    smsChange: (email, new_password, token, id) =>  {
      const smsState = state.smsReset
      dispatch(setError(""))
      axios
          .post(
              `${ORDERS_API_URL}/sms_change`,
              {
                email,
                new_password,
              },
              {
                headers: {
                  Authorization: token,
                },
              }
          )
          .then((res) => {
            dispatch({
              type: "SMS_RESET",
              payload: { ...smsState, success: true },
            })
          })
          .catch((err) => {
            dispatch(setError("Could not reset password"))
          })
    },
    clearStateLogin: (history) => {
      dispatch({
        type: "SMS_RESET",
        payload: { email: "", code: null, token: "", id: null },
      })
      history.push("/login")
    },
    getPaymentToken: (user, token) => {
      axios
        .get(`${API_URL}/payment/client-token/${user}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          dispatch({
            type: "ADD_CARD_TOKEN",
            payload: res.data.token,
          });
        })
        .catch((err) => {
          dispatch({
            type: "ERRORS",
            payload: "Could not get token",
          });
        });
    },
    addCard: (
      first_name,
      last_name,
      email,
      nonceFromTheClient,
      customer_id,
      token,
      payload
    ) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/payment/add-card/${customer_id}`,
          {
            first_name,
            last_name,
            email,
            nonceFromTheClient,
            customer_id,
            deviceData: payload.deviceData,
            billing_address: payload.streetAddress,
            billing_city: payload.locality,
            billing_name: payload.nameOnCard,
            billing_postcode: payload.postalCode,
            billing_state: payload.region,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "SUCCESS",
            payload: "Successfully added payment method.",
          });
          dispatch({ type: "CARD_ADDED", payload: true });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "ERROR",
            payload:
              err.response.data.Error &&
              err.response.data.Error.indexOf("avs") !== -1
                ? "Unable to place order. Payment Authorizer declined the authorization due to Address not matching"
                : err.response.data.reason
                ? "Error with checkout" + " " + err.response.data.reason
                : "Unable to add payment due to either address not matching or CVV not matching.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    },
    logout: () => {
      dispatch({
        type: "USER_DETAILS",
        payload: {
          token: "",
          ngrp_id: "",
          existing_payment: false,
          user_id: "",
          billing_phone: "",
          username: "",
          first_name: "",
          last_name: "",
        },
      });
      dispatch({
        type: "USER_AUTH",
        payload: false,
      });
      dispatch({
        type: "ADD_CARD_TOKEN",
        payload: "",
      });
      Cookies.remove("userDetails");
      history.push("/login");
    },
    palmCode: (store_id, device_id, customer_id) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      dispatch({
        type: "STEP",
        payload: 3,
      });
      axios
        .post(
          `${API_URL}/customer/add_palm_id/${customer_id}`,
          {
            store_id,
            device_id,
          },
          {
            headers: {
              Authorization: AUTH1,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "SUCCESS",
            payload: res.data.message,
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
          setTimeout(() => {
            dispatch({
              type: "STEP",
              payload: 4,
            });
          }, 10000);
        })
        .catch((err) => {
          dispatch({
            type: "ERROR",
            payload: "Error accessing QR code.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    palmEnroll: (otp_id, token) => {
      const checkPalmEnrollStatus = () => {
        // dispatch({
        // 	type: "LOADING",
        // 	payload: true,
        // }); status = Failure -> step 1 and show retry
        // status = in-progress -> need to call an API for every 5 sec
        // status = enroll - > step 4
        const checkEnrollAPIStatus = setInterval(async () => {
          try {
            const res = await axios.get(`${API_URL}/palmId/enroll/status`, {
              headers: {
                Authorization: token,
              },
            });
            console.log("res-->", res);
            const { success, status } = res.data;
            if (success && status === "enroll") {
              clearInterval(checkEnrollAPIStatus);
              dispatch({
                type: "SUCCESS",
                payload: res.data.message,
              });
              dispatch({
                type: "LOADING",
                payload: false,
              });
              dispatch({
                type: "STEP",
                payload: 4,
              });
            } else if (status === "failed") {
              clearInterval(checkEnrollAPIStatus);
              dispatch({
                type: "ERROR",
                payload: "Unable to request your process at this time.",
              });
              dispatch({
                type: "LOADING",
                payload: false,
              });
              dispatch({
                type: "STEP",
                payload: 2,
              });
            }
          } catch (e) {
            console.log("do continue, even error!");
          }
        }, 5000);
      };
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/palmId/enroll`,
          {
            otp_id,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "API_ERROR",
            payload: "",
          });
          dispatch({
            type: "SUCCESS",
            payload: res.data.message,
          });
          console.log("res-->", res);
          dispatch({
            type: "STEP",
            payload: 3,
          });
          checkPalmEnrollStatus();
        })
        .catch((err) => {
          dispatch({
            type: "API_ERROR",
            payload: "Unable to request your process at this time.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    updateName: (first_name, last_name, customer_id, token) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/customer/change_name/${customer_id}`,
          {
            first_name,
            last_name,
          },
          {
            headers: {
              Authorization: token,
              apikey: API_KEY,
            },
          }
        )
        .then((res) => {
          let response = res.data;
          Cookies.set("userDetails", {
            ...state.userDetails,
            first_name: response.firstName,
            last_name: response.lastName,
          });
          dispatch({
            type: "USER_DETAILS",
            payload: {
              ...state.userDetails,
              first_name: response.firstName,
              last_name: response.lastName,
            },
          });
          dispatch({
            type: "SUCCESS",
            payload: "Successfully updated customer name.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "ERROR",
            payload: "Error changing customer name.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    updateEmail: (username, new_email, customer_id, token) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/customer/update_email/${customer_id}`,
          {
            username,
            new_email,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          let response = res.data;
          Cookies.set("userDetails", {
            ...state.userDetails,
            username: response.new_email,
          });
          dispatch({
            type: "USER_DETAILS",
            payload: {
              ...state.userDetails,
              username: response.new_email,
            },
          });
          dispatch({
            type: "SUCCESS",
            payload: "Successfully updated email address.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "ERROR",
            payload: "Error changing email address.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    updatePhone: (new_phone, customer_id, token) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/customer/update_phone/${customer_id}`,
          {
            new_phone,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          let response = res.data;
          Cookies.set("userDetails", {
            ...state.userDetails,
            billing_phone: response.phone,
          });
          dispatch({
            type: "USER_DETAILS",
            payload: {
              ...state.userDetails,
              billing_phone: response.phone,
            },
          });
          dispatch({
            type: "SUCCESS",
            payload: "Successfully updated phone number.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "ERROR",
            payload: "Error changing phone number.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    updatePassword: (email, new_pass, customer_id, token) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
            `${API_URL}/customer/change_password/${customer_id}`,
          {
            email,
            new_pass,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "SUCCESS",
            payload: "Successfully updated password.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "ERROR",
            payload: "Error changing password.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    deleteAccount: (customer_id, token) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/customer/delete_user/${customer_id}`,
          {
            customer_id,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "SUCCESS",
            payload: "Successfully deleted account.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "ERROR",
            payload: "Error deleting account. Please contact support.",
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
    getStoreAddress: (store_id) => {
      dispatch({
        type: "LOADING",
        payload: true,
      });
      axios
        .post(
          `${API_URL}/customer/get_store_info`,
          {
            store_id,
          },
          {
            headers: {
              apikey: API_KEY,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: "STORE_ADDRESS",
            payload: res.data[0],
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
        })
        .catch((err) => {
          dispatch({
            type: "LOADING",
            payload: false,
          });
        });
    },
  };
};
