import {useContext, useEffect, useState} from "react";
import { STATE_NAME } from "../../utils/constants";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import {Context} from "../../context";

const EditBillingAddress = (props) => {
  const { state, dispatch } = useContext(Context);
  const [currentLang, setCurrentLang] = useState(state.lang);
  const {braintree:brainTreePage,save: saveText} = state.languages;
  // form validation rules for STEP ONE of signup
  const validationSchema = Yup.object().shape({
    streetAddress: Yup.string().required(brainTreePage.street_address_required[currentLang]),
    region: Yup.string().required(brainTreePage.state_required[currentLang]),
    locality: Yup.string().required(brainTreePage.city_address_required[currentLang]),
    postalCode: Yup.string().required(brainTreePage.postal_required[currentLang]),
    nameOnCard: Yup.string().required(brainTreePage.name_on_card_required[currentLang])
  });
  const [isShowingModal, toggleModal] = useState(true);
  const [maskedCard, setMaskedCard] = useState(null);
  const { getValues, register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
    defaultValues: {
      nameOnCard: props.selectedCard?.billing_info?.billing_name || "",
      streetAddress: props.selectedCard?.billing_info?.billing_address,
      locality: props.selectedCard?.billing_info?.billing_city,
      region: props.selectedCard?.billing_info?.billing_state,
      postalCode: props.selectedCard?.billing_info?.billing_postcode,
    },
  });

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);

  // const watchAllFields = watch();
  let values = getValues();

  function onSubmit(data) {
    console.log(data);
  }

  const handleFormSubmit = async (e) => {
    await handleSubmit(onSubmit)(e);
    return false;
  };

  useEffect(() => {
    setMaskedCard(props.selectedCard.card_info.last_4_digits);
    toggleModal(true);
  }, []);

  const handleAddBillingAddress = () => {
    handleSubmit(() => {})();
    const values = getValues();
    if (
      values.nameOnCard &&
      values.streetAddress &&
      values.locality &&
      values.region &&
      values.postalCode
    ) {
      // cardId,nameOnCard,streetAddress,locality,region,postalCode
      //(nameOnCard,streetAddress,locality,region,postalCode)
      props.editBillingAddress(
        values.nameOnCard,
        values.streetAddress,
        values.locality,
        values.region,
        values.postalCode
      );
    }
  };

  const onModalClose = () => {
    props.setSelectedCard(null);
    toggleModal(false);
  };

  return (
    <>
      <Modal
        isShowing={isShowingModal}
        hide={onModalClose}
        className={"change-payment-modal"}
        allowClose
      >
        <div className="d-block pb-3 edit-billing-address">
          <p>
            <strong>
              {`${brainTreePage.edit_your_billing_for[currentLang]} -`}
              {maskedCard}
            </strong>
          </p>
          <div className={"col-12"}>
            <form
              id="completeCheckout"
              className="row"
              onSubmit={(e) => handleFormSubmit(e)}
              onReset={reset}
              noValidate
              autoComplete="off"
              style={{ width: "100%" }}
            >
              <div className="form-group col-12 col-md-12">
                <label htmlFor="nameOnCard">
                  <span className="required">*</span>
                  {brainTreePage.name_on_card[currentLang]}
                </label>
                <input
                  name="nameOnCard"
                  type="text"
                  id="nameOnCard"
                  placeholder={brainTreePage.name_on_card_placeholder[currentLang]}
                  defaultValue={values.nameOnCard}
                  ref={register}
                  className={`form-control ${
                    errors.nameOnCard ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback form-invalid-message">
                  {errors.nameOnCard?.message}
                </div>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="streetAddress">
                  <span className="required">*</span>
                  {brainTreePage.street_address[currentLang]}
                </label>
                <input
                  name="streetAddress"
                  type="text"
                  id="streetAddress"
                  placeholder={brainTreePage.street_address[currentLang]}
                  defaultValue={values.streetAddress}
                  ref={register}
                  className={`form-control ${
                    errors.streetAddress ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback form-invalid-message">
                  {errors.streetAddress?.message}
                </div>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="locality">
                  <span className="required">*</span>
                  {brainTreePage.city[currentLang]}
                </label>
                <input
                  name="locality"
                  type="text"
                  id="locality"
                  placeholder={brainTreePage.city[currentLang]}
                  defaultValue={props.locality}
                  ref={register}
                  className={`form-control ${
                    errors.locality ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback form-invalid-message">
                  {errors.locality?.message}
                </div>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="region">
                  <span className="required">*</span>
                  {brainTreePage.state[currentLang]}
                </label>
                <select
                  name={"region"}
                  id={"region"}
                  placeholder={brainTreePage.state[currentLang]}
                  defaultValue={props.region}
                  ref={register}
                  className={`form-control ${
                    errors.region ? "is-invalid" : ""
                  }`}
                >
                  <option value={""}>{brainTreePage.select_state[currentLang]}</option>
                  {STATE_NAME.map((sName) => (
                    <option value={sName} key={"state_names_" + sName}>
                      {sName}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback form-invalid-message">
                  {errors.region?.message}
                </div>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="postalCode">
                  <span className="required">*</span>
                  {brainTreePage.postal_code[currentLang]}
                </label>
                <input
                  name="postalCode"
                  type="text"
                  id="postalCode"
                  placeholder={brainTreePage.postal_code[currentLang]}
                  defaultValue={props.postalCode}
                  ref={register}
                  className={`form-control ${
                    errors.postalCode ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback form-invalid-message">
                  {errors.postalCode?.message}
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 text-center">
            <Button
              id="bt-submit-button"
              className={`btn btn-main btn-small my-3`}
              event={handleAddBillingAddress}
            >
              {saveText[currentLang]}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditBillingAddress;
