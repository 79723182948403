/** @format */

import React, {useContext, useEffect, useState} from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import {useUser} from "../../../custom-hooks/useUser";
import {Context} from "../../../context";


const EnterCode = () => {
    // form validation rules
    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required("Code is required")
            .min(6, "Code must be at least 6 digits"),
    })
    const user = useUser();
    const {state,dispatch} = useContext(Context);

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, errors } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "all",
    })


    const [currentLang, setCurrentLang] = useState(state.lang);
    const {signup_signin_page: signinPage,submit: submitText} = state.languages;

    useEffect(() => {
        setCurrentLang(state.lang);
    }, [state.lang]);


    // On submit function fired when form submission is made
    function onSubmit(data) {
        user.smsCheck(state.smsReset.email, data.code)
    }
    useEffect(()=>{
        dispatch({type: 'ERROR',payload: ''});
    },[]);
    useEffect(()=>{
        if(state.error){
            setTimeout(()=>{
                dispatch({type: 'ERROR',payload: ''});
            },5000);
        }
    }, [state.error]);

    return (
        <div className="row d-flex justify-content-center">
            <form
                id="smsCode"
                className="row d-flex justify-content-center col-12 col-lg-8"
                onSubmit={handleSubmit(onSubmit)}
                onReset={reset}
                noValidate
                autoComplete="off"
            >
                <div className="form-group b-block position-relative">
                    <p>
                        {signinPage.code_sent[currentLang]}
                    </p>
                    <p>Please enter it here.</p>

                    <input
                        name="code"
                        label={signinPage.digit_code[currentLang]}
                        type="number"
                        placeholder="Code"
                        ref={register}
                        className={`form-control ${
                            errors.code ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback form-invalid-message d-block text-center">
                        {errors.code?.message || state.error}
                    </div>
                </div>
                <div className="form-row col-10 d-grid">
                    <button
                        type="submit"
                        form="smsCode"
                        className="btn btn-main btn-main-lg btn-block mt-4"
                    >
                        {signinPage.continue[currentLang]}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EnterCode;
