/** @format */

import React, {useContext, useEffect, useState} from "react";
import { Context } from "../../context";
import { Link } from "react-router-dom";
import PalmAnimation from "./img/palm.gif";
import "./styles.css";

const Home = () => {
  // Get the context provider
  const { state } = useContext(Context);

  // Dispatch the location for GA tracking through GTM
  // useEffect(() => {
  // 	dispatch({
  // 		type: "GTM_PATH",
  // 		payload: location.pathname,
  // 	});
  // }, []);
  const [currentLang, setCurrentLang] = useState(state.lang);
  const {home_page: homePage} = state.languages;

  useEffect(() => {
    setCurrentLang(state.lang);
  }, [state.lang]);


  return (
    <div className="container-fluid h-100 home-page">
      <div className="row d-flex h-100 justify-content-center">
        <div className="col-12 text-center home-page-heading">
          <h1>
            Palm <span>ID</span>
          </h1>
          <p>{homePage.home_page_title[currentLang]}</p>
          <img src={PalmAnimation} alt="Hand" className="home-gif mt-4" />
          <span className="hand-gif-label d-block mt-3">Hand ID</span>
        </div>
        <div className="col-10 align-self-end text-center">
          <div className="row">
            <div className="col-12">
              <Link
                to="/register"
                className="btn btn-main btn-main-lg btn-block home-page-register"
              >
                {homePage.register_today[currentLang]}
              </Link>
            </div>
            <div className="col-12">
              <Link to="/login" className="btn btn-block home-page-signin">
                {homePage.signIn[currentLang]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
